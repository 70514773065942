<template>
  <div class="master-toko">
    <header-page>
      <span class="size14 fw-bold-700" style="color: #0b5fff; margin-right: 5px"
        >Master</span
      >
      <span class="text-dark text-darken-5" style="margin-right: 5px">></span>
      <span class="text-dark fw-bold-400 size14">Lokasi SKU</span>
      <template v-slot:action>
        <div class="w-75">
          <b-input-group class="input-group-merge">
            <b-form-input v-model="filter.search" placeholder="Search" />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </div>
      </template>
    </header-page>

    <b-container fluid v-if="isLoading == false" class="mt-2">
      <b-row>
        <b-col cols="12">
          <h4 class="text-black-2 fw-bold-700 size16 mb-1">
            Daftar Lokasi SKU
          </h4>
        </b-col>
        <b-col
          v-for="(warehouse, index) in result.data"
          :key="index"
          cols="12"
          md="6"
          lg="6"
          class="mb-1"
        >
          <b-card class="px-16 py-16 border-12 mb-0" no-body>
            <div class="d-flex flex-column">
              <div class="d-flex mb-2 justify-content-between">
                <div class="">
                  <h3 class="text-black-2 fw-bold-700 size20">
                    {{ warehouse.name }}
                  </h3>
                  <h6 class="text-dark fw-bold-500 size14">
                    {{ warehouse.name }} • {{ warehouse.city }}
                  </h6>
                  <span class="text-dark text-darken-4 fw-bold-600 size14 mb-0">
                    {{ warehouse.address }}
                  </span>
                </div>
                <b-img
                  width="100"
                  height="100"
                  style="object-fit: cover"
                  class="ml-1"
                  rounded="circle"
                  :src="warehouse.logo_url"
                />
              </div>
              <div class="d-flex">
                <b-button
                  v-if="checkPermission('update gudang')"
                  class="bg-white mr-1 px-3 btn-block"
                  style="box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04)"
                  @click="editItem(warehouse)"
                >
                  <b-img :src="require('@/assets/images/icons/Process2.svg')" />
                  Ubah
                </b-button>
                <b-button
                  v-if="checkPermission('delete gudang')"
                  class="border-0 border-8 btn-delete bg-pink p-1"
                  @click="deleteData(warehouse.uuid)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    size="18"
                    class="text-danger"
                  />
                </b-button>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col cols="12" md="6" lg="6" class="mb-1">
          <b-card
            v-if="checkPermission('add gudang')"
            class="px-3 py-18 border-12 mb-0"
            style="cursor: pointer; height: 199px"
            no-body
            @click="$bvModal.show('modal-gudang')"
          >
            <div
              class="d-flex flex-column align-items-center justify-content-center h-100"
            >
              <h6>
                <feather-icon icon="PlusIcon" size="28" />
              </h6>
              <h6 class="text-dark fw-bold-800 mb-0">Tambah lokasi SKU baru</h6>
            </div>
          </b-card>
        </b-col>
        <b-col cols="12">
          <pagination
            class="mb-0 mt-2"
            :data="result"
            :limit="4"
            align="center"
            size="large"
            @pagination-change-page="getData"
          />
        </b-col>
      </b-row>
    </b-container>

    <b-col cols="12" class="mt-5">
      <div v-if="isLoading == true" class="d-flex justify-content-center mb-1">
        <b-spinner label="Loading..." />
      </div>
    </b-col>

    <ModalAddGudang
      :form-data="formPayload"
      :create-item="createItem"
      :edit-id="editId"
      :edit-item="editItem"
      :update-item="updateItem"
      :clean-up-form="cleanUpForm"
      :logo="logo"
      @getPayload="getPayload"
    />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from "lodash";
import {
  BContainer,
  BButton,
  BImg,
  VBToggle,
  BRow,
  BCol,
  BCard,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BSpinner,
} from "bootstrap-vue";

import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";

import HeaderPage from "@/components/HeaderPage.vue";
import ModalAddGudang from "@/components/Master/Gudang/Modal/ModalAddGudang.vue";

export default {
  title() {
    return "Master Lokasi SKU";
  },
  components: {
    BButton,
    HeaderPage,
    BImg,
    BRow,
    BCol,
    BContainer,
    BCard,
    BFormInput,
    BSpinner,
    BInputGroup,
    BInputGroupAppend,
    ModalAddGudang,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      isLoading: false,
      result: {},
      editId: null,
      logo: null,
      formPayload: {
        name: "",
        address: "",
        city: "",
        logo: "",
      },
      filter: {
        search: "",
        per_page: 10,
      },
    };
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData();
      }, 300),
      deep: true,
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getPayload(value) {
      this.formPayload = value;
    },
    cleanUpForm() {
      this.editId = null;
      this.logo = null;
      this.formPayload = {
        name: "",
        address: "",
        city: "",
        logo: "",
      };
    },
    editItem(item) {
      this.cleanUpForm();
      this.editId = item.uuid;
      this.logo = item.logo_url;
      this.$store.commit(
        "warehouse/setFormPayload",
        (this.formPayload = {
          name: item.name,
          address: item.address,
          city: item.city,
        })
      );
      this.$bvModal.show("modal-gudang");
    },
    async getData(page = 1) {
      this.isLoading = true;
      const queryParams = this.filter;
      queryParams.page = page;
      this.$store
        .dispatch("warehouse/getData", { params: queryParams })
        .then((result) => {
          this.isLoading = false;
          this.result = result.data.data;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    createItem() {
      this.$swal({
        title: "Konfirmasi",
        text: "Apakah Anda yakin ingin membuat Lokasi SKU baru?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          const form = this.preparePayload();
          this.isLoading = true;
          this.$store
            .dispatch("warehouse/postData", {
              query: "",
              payload: form,
            })
            .then(() => {
              this.getData();
              successNotification(this, "Success", "Gudang berhasil dibuat");
              this.cleanUpForm();
              this.$bvModal.hide("modal-gudang");
              this.isLoading = false;
            })
            .catch((error) => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
              this.isLoading = false;
            });
        }
      });
    },
    updateItem() {
      this.$swal({
        title: "Konfirmasi",
        text: "Apakah Anda yakin ingin mengubah data Lokasi SKU ini?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          const form = this.preparePayload();
          this.isLoading = true;
          form.append("_method", "PATCH");

          this.$store
            .dispatch("warehouse/postData", {
              query: `/${this.editId}`,
              payload: form,
            })
            .then(() => {
              this.$bvModal.hide("form-bank-modal");
              this.getData();
              successNotification(this, "Success", "Gudang berhasil diubah!");
              this.cleanUpForm();
              this.isLoading = false;
              this.$bvModal.hide("modal-gudang");
            })
            .catch((error) => {
              if (error.response.data.meta.validations) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
              this.isLoading = false;
            });
        }
      });
    },
    async deleteData(uuid) {
      this.$swal({
        title: "Konfirmasi",
        text: "Apakah Anda yakin ingin menghapus data Lokasi SKU ini?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("warehouse/deleteData", `/${uuid}`)
            .then(() => {
              this.getData();
              this.$swal({
                icon: "success",
                title: "Sukses!",
                text: "Gudang berhasil dihapus",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            })
            .catch((error) => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
            });
        }
      });
    },
    preparePayload() {
      const form = new FormData();
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (
          this.formPayload.hasOwnProperty(key) &&
          this.formPayload[key] != null
        ) {
          form.append(key, this.formPayload[key]);
        }
      }
      return form;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";

.px-33 {
  padding-left: 33px;
  padding-right: 33px;
}
.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}
.py-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.py-18 {
  padding-top: 18px;
  padding-bottom: 18px;
}

#app {
  background: #f6f7fa;
}

.master-toko {
  height: 100vh;
}
</style>
